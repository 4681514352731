module.exports = [{
      plugin: require('/Users/thomas/Code/ravinggenius-personal-profile/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-41029650-1","anonymize":true,"respectDNT":true},
    },{
      plugin: require('/Users/thomas/Code/ravinggenius-personal-profile/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/thomas/Code/ravinggenius-personal-profile/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
